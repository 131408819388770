import { Flex, Box, Table, Tbody, Td, Text, Th, Thead, Tr, Select, Button, Alert, AlertIcon, useToken } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { HSeparator } from 'components/separator/Separator';
import axios from 'axios';
import { CircularProgress } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function PositionPage() {
    const [preLoading, setPreLoading] = useState(false);
    const [preLoadingText, setPreLoadingText] = useState('');
    const [preLoadingStatus, setPreLoadingStatus] = useState(null);
    const [filterData, setFilterData] = useState([]);
    const [mainData, setMainData] = useState([]);
    const [total_positions, setTotalPositions] = useState();
    const [current_page, setCurrnetPage] = useState(1);
    const [pageStatus, setPageStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [symbolValue, setSymbol] = useState('');
    const [positionCaption, setPositionCaption] = useState('Open Positions');
    const serverApi = process.env.REACT_APP_API;
    const [oddRowColor] = useToken('colors', ['gray.200']);

    const urlParams = new URLSearchParams(window.location.search);
    let userRole = localStorage.getItem('role');
    let userid: any;
    if (userRole !== "admin") {
        userid = localStorage.getItem('user_id');
    } else {
        if (urlParams.get('user_id')) {
            userid = urlParams.get('user_id');
        } else {
            userid = '0'
        }
    }
    const [state, setState ] = useState({
        selectedOption: 10,
        options: [
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30" }
        ]
    });

    const fetchData = async () => {
        setLoading(true);
        try {
            const tableResponse = await axios.get(`${serverApi}positions/?page=1&limit=${state.selectedOption}&user_id=${userid}&status=1&symbol=${symbolValue}`);
            const response = await axios.get(`${serverApi}getsymbollist/?user_id=${userid}`);
            setFilterData(response?.data?.result);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setPreLoadingText("Fetch positions failed");
            setPreLoadingStatus("error");
            setPreLoading(true);
            setTimeout(() => setPreLoading(false), 2000);
            setLoading(false);
        }
    };

    useEffect(() => {
        // Define an async function to fetch data
        fetchData();
    }, []);
    const handleChange = async (event: any) => {
        await setState({ ...state, selectedOption: event.target.value });
        const status = pageStatus ? 2 : 1;
        const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page}&limit=${event.target.value}&user_id=${userid}&status=${status}&symbol=${symbolValue}`);
        setMainData(tableResponse?.data?.result);
        setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        setCurrnetPage(current_page);
    };
    const handleClosed = async () => {
        setPositionCaption("Closed Positions")
        setPageStatus(true);
        setLoading(true);

        setCurrnetPage(1);
        try {
            const tableResponse = await axios.get(`${serverApi}positions/?page=1&limit=${state.selectedOption}&user_id=${userid}&status=2&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setPreLoadingText("Fetch positions failed");
            setPreLoadingStatus("error");
            setPreLoading(true);
            setTimeout(() => setPreLoading(false), 2000);
            setLoading(false);
        }
    }
    const handleOpened = async () => {
        setPositionCaption("Open Positions")
        setCurrnetPage(1);
        setPageStatus(false);
        setLoading(true);

        try {
            const tableResponse = await axios.get(`${serverApi}positions/?page=1&limit=${state.selectedOption}&user_id=${userid}&status=1&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setPreLoadingText("Fetch positions failed");
            setPreLoadingStatus("error");
            setPreLoading(true);
            setTimeout(() => setPreLoading(false), 2000);
            setLoading(false);
            
        }
    }
    const handleClosedButton = async (data: any) => {
        try {
            setPreLoadingText("Position Close is in processing");
            setPreLoadingStatus("success");
            setPreLoading(true);
            setLoading(true)
            setTimeout(() => setPreLoading(false), 2000);
            const tableResponse: any = await axios.post(`${serverApi}close_position`, {
                "id": data.id,
                "tv_id": data.tv_id,
                "password": "5555"
            });

console.log("message", tableResponse?.data?.message);

            if (tableResponse?.data?.status === true) {
                setPreLoadingText("Position Closed");
                setPreLoadingStatus("success");
                setPreLoading(true);
            } else {
                setPreLoadingText(`Position Close failed. Reason: ${tableResponse?.data?.message}`);
                setPreLoadingStatus("error");
                setPreLoading(true);
            }
            setTimeout(() => setPreLoading(false), 2000);
            await fetchData();
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setPreLoadingText(`Position Close failed. Reason: ${error}`);
            setPreLoadingStatus("error");
            setPreLoading(true);
            setTimeout(() => setPreLoading(false), 2000);
            setLoading(false);

        }
    }

    const nextPage = async () => {
        if (pageStatus === false) {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page + 1}&limit=${state.selectedOption}&user_id=${userid}&status=1&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        } else {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page + 1}&limit=${state.selectedOption}&user_id=${userid}&status=2&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        }
        setCurrnetPage(current_page + 1);
    }
    const beforePage = async () => {
        if (pageStatus === false) {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page - 1}&limit=${state.selectedOption}&user_id=${userid}&status=1&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        } else {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page - 1}&limit=${state.selectedOption}&user_id=${userid}&status=2&symbol=${symbolValue}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        }
        if (current_page > 1) {
            setCurrnetPage(current_page - 1);
        }

    }
    const SearchData = async (symbol: any) => {
        if (pageStatus === false) {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page}&limit=${state.selectedOption}&user_id=${userid}&status=1&symbol=${symbol}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        } else {
            const tableResponse = await axios.get(`${serverApi}positions/?page=${current_page}&limit=${state.selectedOption}&user_id=${userid}&status=2&symbol=${symbol}`);
            setMainData(tableResponse?.data?.result);
            setTotalPositions(tableResponse?.data?.result[0]?.total_positions);
        }
        if (symbol === "") {
            setSymbol('');
        } else {
            setSymbol(symbol);
        }
    }
    const DateTimeConverter = (originalDateTimeString: string) => {
        if (originalDateTimeString === null || originalDateTimeString === '') {
            return;
        }
        const dateTime = new Date(originalDateTimeString);

        const formattedTime = dateTime.toLocaleTimeString([], { hour12: false });
        const formattedDate = dateTime.toLocaleDateString();
        return `${formattedDate}`;

    }
    return (
        <Box>
        {
            preLoading === true ? 
            <Alert status={preLoadingStatus} position='fixed' top='20px' width='280px' right='20px' zIndex='99'>
            <AlertIcon />
             {preLoadingText}
            </Alert> : ""
        }
            <Box>
                <form>
                    <Flex flexDirection='row' gap='3' w={{ sm: '100%', lg: '300px' }}>
                        <Select placeholder='All' onChange={(e) => SearchData(e.target.value)}>
                            {
                                filterData && filterData.map((data: any, index: any) => {
                                    return <option key={index} value={data.symbol}>{data.symbol}</option>
                                })
                            }
                        </Select>
                    </Flex>
                </form>
            </Box>
            <Card flexDirection='column' w='100%' px='20px' mt='10px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Box>
                    <Text fontSize='20px' mb='20px'>{positionCaption}</Text>
                    <Box>
                        <Button colorScheme='green' borderRadius='10px' size='sm' onClick={(e) => handleOpened()}>Open Position</Button>
                        <Button colorScheme='red' ml='2' borderRadius='10px' size='sm' onClick={(e) => handleClosed()}>Closed Position</Button>
                    </Box>
                    <HSeparator my='20px' />
                    {
                        loading === true ? <CircularProgress isIndeterminate color='lightblue' /> : <> <Table variant='simple' color='gray.900' mb='24px' mt="12px" size="sm" className="table-tiny">
                            {pageStatus === false ? <Thead>
                                <Tr height='60px'>
                                    <Th padding='1' fontSize='16px' textAlign='left' fontWeight='500' >
                                        ID
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='left' fontWeight='500' >
                                        Symbol
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Side
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Amount
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Order Value
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Open Price
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Close Price
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Profit USDT
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Profit %
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Status
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Open Date
                                    </Th>
                                </Tr>
                            </Thead> : <Thead>
                                <Tr height='60px'>
                                    <Th padding='1' fontSize='16px' textAlign='left' fontWeight='500'>
                                        ID
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='left' fontWeight='500'>
                                        Symbol
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Side
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Amount
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Order Value
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Open Price
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Close Price
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Profit USDT
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='right' fontWeight='500'>
                                        Profit %
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Status
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Opened Date
                                    </Th>
                                    <Th padding='1' fontSize='16px' textAlign='center' fontWeight='500'>
                                        Close Date
                                    </Th>
                                </Tr>
                            </Thead>}
                            {
                                pageStatus === false ? <Tbody>
                                    {
                                        mainData && mainData.map((data: any, index: any) => {
                                            return (
                                                <Tr key={index} bg={index % 2 === 0 ? oddRowColor : ''}>
                                                    <Td fontSize='lg' padding='1' textAlign="left">
                                                        {data.tv_id}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' color='blue.400' textAlign="left">
                                                        {data.symbol}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="center">
                                                        {data.open_side === 'sell' ? <Button colorScheme='red' borderRadius='5px' fontSize='10px' size='xs' border='none'>Short</Button> : <Button colorScheme='green' borderRadius='5px' fontSize='10px' size='xs' border='none'>Long</Button>}

                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.open_amount).toFixed(4)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.open_cost).toFixed(4)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.open_price).toFixed(4)}
                                                    </Td>

                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {data.close_price}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {data.profit_net_usdt}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {data.profit_percentage}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign='center'>
                                                        <Text mb='2px'>
                                                            Open
                                                        </Text>
                                                        {userRole === 'admin' ? (
                                                            <Button colorScheme='red' borderRadius='5px' size='md' border='none' onClick={(e) => handleClosedButton(data)}>Close Position</Button>
                                                        ) : null}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign='center'>
                                                        <Text style={{ whiteSpace: 'nowrap' }}>{DateTimeConverter(data.open_created_at)}</Text>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody> : <Tbody>
                                    {
                                        mainData && mainData.map((data: any, index: any) => {
                                            return (
                                                <Tr key={index} bg={index % 2 === 0 ? oddRowColor : ''}>
                                                    <Td fontSize='lg' padding='1' textAlign="left">
                                                        {data.tv_id}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' color='blue.400' textAlign="left">
                                                        {data.symbol}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="center">
                                                        {data.side !== 'Sell' ? <Button colorScheme='red' borderRadius='5px' fontSize='10px' size='xs' border='none'>Short Close</Button> : <Button colorScheme='green' borderRadius='5px' fontSize='10px' size='xs' border='none'>Long Close</Button>}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.closedSize).toFixed(2)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {(Number(data.cumEntryValue)* Number(data.leverage ? data.leverage : 1)).toFixed(4)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.avgEntryPrice).toFixed(3)}
                                                    </Td>

                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.avgExitPrice).toFixed(3)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(data.closedPnl).toFixed(3)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign="right">
                                                        {Number(Number(data.closedPnl) / Number(Number(data.avgEntryPrice) * Number(data.closedSize)) * 100).toFixed(2)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1' textAlign='center'>
                                                        <Text mb='2px'>
                                                            Closed
                                                        </Text>
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        <Text style={{ whiteSpace: 'nowrap' }}>{DateTimeConverter(data.opened_at)}</Text>
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        <Text style={{ whiteSpace: 'nowrap' }}>{DateTimeConverter(data.createdAt)}</Text>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            }

                        </Table>
                            <Flex justifyContent='space-between'>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Text whiteSpace="nowrap">{Number(state.selectedOption) * (current_page - 1) + 1} - {Number(state.selectedOption) * current_page} of {total_positions} Items per page</Text>
                                    <Select value={state.selectedOption} onChange={handleChange} marginLeft='3'>
                                        {state.options.map((option: any) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>
                                <Flex justifyContent='center' textAlign='center' alignItems='center'>
                                    {
                                        current_page === 1 ? <Button cursor='not-allowed'><ArrowBackIcon /></Button> : <Button onClick={(e) => beforePage()}><ArrowBackIcon /></Button>
                                    }

                                    <Text px='10px'>{current_page}</Text>
                                    {
                                        current_page < Math.floor(Number(total_positions) / 10) + 1 ? <Button onClick={(e) => nextPage()}><ArrowForwardIcon /></Button> : <Button cursor='not-allowed'><ArrowForwardIcon /></Button>
                                    }
                                </Flex>
                            </Flex></>
                    }

                </Box>
            </Card>
        </Box>

    );
} 